import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import GameControllerIcon from 'shared/designTokens/icons/content/GameControllerIcon';
import WinkFaceIcon from 'shared/designTokens/icons/content/WinkIcon';

import { H3, P } from 'site/src/components/styled';
import Button from 'app/src/components/ui/Button';
import GridContainer from 'app/src/components/ui/GridContainer';
import TextInput from 'app/src/components/input/TextInput';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import SelectInput from 'app/src/components/input/SelectInput';

import { engines } from 'shared/vars';

const FormTitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
`;

const FormTitle = styled(H3)`
	font-size: 24px;
	line-height: 30px;
	margin: 0;
`;

const StyledTextInput = styled(TextInput)`
	margin-bottom: 0;
	max-width: 400px;
	line-height: 24px;

	${props => props.fullWidth && `
	max-width: 100%;
	`}
`;

const baseIconStyles = css`
	[fill] {
		fill: ${props => props.theme.denimBlue}
	}
`;

const StyledGameIcon = styled(GameControllerIcon)`
	${baseIconStyles}
`;

const StyledWinkFaceIcon = styled(WinkFaceIcon)`
	${baseIconStyles}
`;

const StyledSelectInput = styled(SelectInput)`
	max-width: 100%;
	line-height: 24px;
`;

const StyledTextAreaInput = styled(TextAreaInput)`
	max-width: 100%;
	line-height: 24px;
`;

const validationSchema = yup.object().shape({
	gameTitle: yup.string().min(2, 'Game title should be at least 2 characters'),
	gameURL: yup.string().url('Please enter a valid URL').required('Please enter a URL')
		.test('not-local-url', 'Local URLs like 127.0.0.1 or localhost are not allowed', value => {
			if (!value) return true;

			return !/^https?:\/\/(127\.0\.0\.1)([:/]|$)/i.test(value);
		}),
	gameEngine: yup.string().required('Please select a game engine'),
	gameInformation: yup.string().min(2, 'Game information should be at least 2 characters').max(400, 'Game information should be at most 400 characters'),
	name: yup.string().min(2, 'Name should be at least 2 characters'),
	email: yup.string().email('Please enter a valid email address').required('Please enter an email address'),
	teamName: yup.string().min(2, 'Team name should be at least 2 characters'),
});

const GameSubmissionForm = props => {
	const { callbackOnSuccess } = props;

	const { handleSubmit, control, formState: { errors } } = useForm({
		defaultValues: {
			gameTitle: '',
			gameURL: '',
			gameEngine: '',
			gameInformation: '',
			name: '',
			email: '',
			teamName: '',
		},
		resolver: yupResolver(validationSchema),
	});

	const [submitting, setSubmitting] = useState(false);

	const onSubmit = async data => {
		setSubmitting(true);

		const apiPayload = {
			game_title: data.gameTitle,
			game_link: data.gameURL,
			game_engine: data.gameEngine,
			game_information: data.gameInformation,
			user_name: data.name,
			user_email: data.email,
			team_name: data.teamName,
		};

		try {
			const response = await fetch('https://devs-api.poki.io/_earlyaccess', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(apiPayload),
			});

			if (response.ok) {
				callbackOnSuccess();
			}
		} catch (error) {
			setSubmitting(false);
			console.error('Error:', error);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<GridContainer cols={1}>
				<FormTitleContainer>
					<FormTitle>About your game</FormTitle>
					<StyledGameIcon />
				</FormTitleContainer>
				<Controller
					control={control}
					name="gameTitle"
					render={({ field: { onChange, value } }) => (
						<StyledTextInput
							label="Your game title"
							placeholder="Enter the title of your game"
							value={value}
							valueSetter={onChange}
							required
							disabled={false}
							errors={errors?.gameTitle?.message ? [errors?.gameTitle?.message] : []}
						/>
					)}
				/>
				<Controller
					control={control}
					name="gameURL"
					render={({ field: { onChange, value } }) => (
						<StyledTextInput
							fullWidth
							label="Link to your game"
							placeholder="URL to your game"
							value={value}
							valueSetter={onChange}
							required
							disabled={false}
							errors={errors?.gameURL?.message ? [errors?.gameURL?.message] : []}
						/>
					)}
				/>
				<Controller
					control={control}
					name="gameEngine"
					render={({ field: { onChange, value } }) => (
						<StyledSelectInput
							label="What engine is your game made in?"
							placeholder="Select an engine"
							value={value}
							valueSetter={onChange}
							required
							disabled={false}
							errors={errors?.gameEngine?.message ? [errors?.gameEngine?.message] : []}
							values={[
								...engines.map(t => ({
									value: t,
									desc: t,
								})),
							]}
						/>
					)}
				/>
				<Controller
					control={control}
					name="gameInformation"
					render={({ field: { onChange, value } }) => (
						<StyledTextAreaInput
							height="178px"
							resize={false}
							label="More information about your game"
							placeholder="Tell us a bit more about your game"
							description="Short description, mobile compatibility, file size, etc."
							value={value}
							valueSetter={onChange}
							errors={errors?.gameInformation?.message ? [errors?.gameInformation?.message] : []}
							required
							disabled={false}
						/>
					)}
				/>
				<FormTitleContainer>
					<FormTitle>About you</FormTitle>
					<StyledWinkFaceIcon />
				</FormTitleContainer>
				<Controller
					control={control}
					name="name"
					render={({ field: { onChange, value } }) => (
						<StyledTextInput
							label="Your name"
							placeholder="Enter your name"
							value={value}
							valueSetter={onChange}
							disabled={false}
							required
							errors={errors?.name?.message ? [errors?.name?.message] : []}
						/>
					)}
				/>
				<Controller
					control={control}
					name="email"
					render={({ field: { onChange, value } }) => (
						<StyledTextInput
							label="Your email address"
							placeholder="Enter your email address"
							value={value}
							valueSetter={onChange}
							disabled={false}
							required
							errors={errors?.email?.message ? [errors?.email?.message] : []}
						/>
					)}
				/>
				<Controller
					control={control}
					name="teamName"
					render={({ field: { onChange, value } }) => (
						<StyledTextInput
							label="Studio or developer name"
							placeholder="Enter your studio or developer name"
							value={value}
							valueSetter={onChange}
							disabled={false}
							required
							errors={errors?.teamName?.message ? [errors?.teamName?.message] : []}
						/>
					)}
				/>
				<P>The team is looking forward to give your game a spin.</P>
				<Button marketingButton submit disabled={submitting}>Share your game</Button>
			</GridContainer>
		</form>
	);
};

export default GameSubmissionForm;
